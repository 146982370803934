// import dayjs lib along with its plugins and load them
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(duration);

/**
 * Convert crontab (m h * * *) (utc) to HH:mm (local) format.
 */
export function convertCrontabToTime(crontabStr: string | null): string {
  if (crontabStr == null) {
    return "";
  }
  const parts = crontabStr.split(" ");
  const dayObj = dayjs()
    .minute(Number(parts[0]))
    .hour(Number(parts[1]))
    .utc(true)
    .local(); // convert utc to local
  if (dayObj.isValid()) {
    return dayObj.format("HH:mm");
  } else {
    return "";
  }
}

/**
 * Convert HH:mm (local) time to crontab (m h * * *) (utc) format.
 */
export function convertTimeToCrontab(time: string, dayOfWeek = "*"): string {
  const dayObj = dayjs(time, "HH:mm", true).utc(); // convert local to utc
  if (dayObj.isValid()) {
    const minute = dayObj.minute();
    const hour = dayObj.hour();
    return `${minute} ${hour} * * ${dayOfWeek}`;
  } else {
    return "";
  }
}

/**
 * Check if all crontab times are equal.
 */
export function checkIfAllWeekCrontabTimesAreEqual(
  startTimeCrontabs: Array<string | null> | null,
  endTimeCrontabs: Array<string | null> | null
): boolean {
  if (startTimeCrontabs != null) {
    for (const crontab of startTimeCrontabs) {
      if (crontab !== startTimeCrontabs[0]) {
        return false;
      }
    }
  }
  if (endTimeCrontabs != null) {
    for (const crontab of endTimeCrontabs) {
      if (crontab !== endTimeCrontabs[0]) {
        return false;
      }
    }
  }
  return true;
}

export function checkIfAllWeekDaysCrontabTimesAreEqual(
  startTimeCrontabs: Array<string | null> | null,
  endTimeCrontabs: Array<string | null> | null
): boolean {
  if (startTimeCrontabs != null) {
    for (const crontab of startTimeCrontabs) {
      if (
        crontab != null &&
        startTimeCrontabs[0] != null &&
        crontab.slice(0, -1) !== startTimeCrontabs[0].slice(0, -1)
      ) {
        return false;
      }
    }
  }
  if (endTimeCrontabs != null) {
    for (const crontab of endTimeCrontabs) {
      if (
        crontab != null &&
        endTimeCrontabs[0] != null &&
        crontab.slice(0, -1) !== endTimeCrontabs[0].slice(0, -1)
      ) {
        return false;
      }
    }
  }
  return true;
}

export function isValidTimeHHmm(s: string): boolean {
  return dayjs(s, "HH:mm", true).isValid();
}

export function extractHHmmFromHHmmss(s: string | null): string | null {
  if (s == null) {
    return null;
  }
  return s.split(":").slice(0, 2).join(":");
}

export function convertHHmmUtcToLocal(time: string | null): string | null {
  if (time == null) {
    return null;
  }
  let dayObj = dayjs.utc(time, "HH:mm", true).local(); // convert utc to local

  const selectedTimezone = localStorage.getItem("selected_timezone");
  if (selectedTimezone) {
    dayObj = dayjs.utc(time, "HH:mm", true).tz(selectedTimezone);
  }

  if (dayObj.isValid()) {
    return dayObj.format("HH:mm");
  } else {
    return null;
  }
}

export function convertHHmmLocalToUtc(time: string | null): string | null {
  if (time == null) {
    return null;
  }
  const localTime = dayjs(time, "HH:mm", true);
  let dayObj = localTime.utc(); // convert local to utc

  const selectedTimezone = localStorage.getItem("selected_timezone");
  if (selectedTimezone) {
    dayObj = localTime.tz(selectedTimezone, true).utc();
  }

  if (dayObj.isValid()) {
    return dayObj.format("HH:mm");
  } else {
    return null;
  }
}

export function convert24HourToAmPm(time24hour: string | null): string | null {
  const dayObj = dayjs(time24hour, "HH:mm");
  if (!dayObj.isValid()) {
    return null;
  }
  return dayObj.format("hh:mm A");
}

export function formatDurationMinutes(durationMinutes: number): string {
  durationMinutes = Math.abs(durationMinutes);
  return dayjs.duration(durationMinutes, "minutes").humanize();
}

export function formatDurationSeconds(durationSeconds: number): string {
  if (durationSeconds == null) {
    return "";
  }
  durationSeconds = Math.abs(durationSeconds);
  const minutes = Math.ceil((durationSeconds % 3600) / 60);
  const hours = Math.floor(durationSeconds / 3600);

  // If not hours needed, return only minutes
  if (hours === 0) {
    if (minutes === 1) {
      return `${minutes} minute`;
    } else {
      return `${minutes} minutes`;
    }
  }

  // If hours needed, then construct "x hours and y minutes" format text
  let text = "";
  if (hours === 1) {
    text = text.concat(`${hours} hour`);
  } else {
    text = text.concat(`${hours} hours`);
  }
  if (minutes === 1) {
    text = text.concat(` and ${minutes} minute`);
  } else if (minutes > 1) {
    text = text.concat(` and ${minutes} minutes`);
  }
  return text;
}

export function formatDurationShowSeconds(durationSeconds: number): string {
  if (durationSeconds == null) {
    return "";
  }
  durationSeconds = Math.abs(durationSeconds);
  const seconds = Math.round(durationSeconds % 60); // Round seconds to the nearest whole number
  const remainingSeconds = Math.abs(durationSeconds - seconds);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);
  const hours = Math.floor(remainingSeconds / 3600);

  // If not hours needed, return only minutes and seconds
  if (hours === 0) {
    let text = "";
    if (minutes > 0) {
      text = `${minutes} minute${minutes === 1 ? "" : "s"}`;
    }
    if (seconds > 0) {
      if (text.length > 0) {
        text += " and ";
      }
      text += `${seconds} second${seconds === 1 ? "" : "s"}`;
    }
    return text;
  }

  // If hours needed, then construct "x hours, y minutes, and z seconds" format text
  let text = `${hours} hour${hours === 1 ? "" : "s"}`;
  if (minutes > 0 || seconds > 0) {
    text += ", ";
    if (minutes > 0) {
      text += `${minutes} minute${minutes === 1 ? "" : "s"}`;
    }
    if (seconds > 0) {
      if (minutes > 0) {
        text += " and ";
      }
      text += `${seconds} second${seconds === 1 ? "" : "s"}`;
    }
  }
  return text;
}
