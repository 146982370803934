



























































































































































































































































































































































































































































import Vue from "vue";
import api from "@/api/api";
import {
  Message,
  MessageCreate,
  MessageUpdate,
  MessageTemplate,
  ParkingLotsList,
} from "@/api/models";
import TimePicker from "../timepicker/TimePicker.vue";

import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import "@/styles/datepicker.scss";

type VForm = Vue & { resetValidation: () => boolean };

export default Vue.extend({
  name: "MessageForm",

  props: {
    lotId: {
      type: Number,
      required: false,
    },
    existingMessage: Object as () => Message | null,
    needsInit: {
      type: Boolean as () => boolean,
      required: true,
    },
    alertTypeId: {
      type: Number,
      required: false,
    },
    alertId: {
      type: Number,
      required: false,
    },
    forwardMessageText: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      id: null as number | null,
      subject: "",
      messageText: "",
      sendInAppMessage: true,
      sendNotificationToAppUsers: false,
      sendEmailToAppUsers: false,
      messageTemplates: {
        data: [] as Array<MessageTemplate>,
        selected: null as number | null,
        isLoading: false,
      },
      minFromDate(): string {
        // Minimum date for the "From" field (e.g., today)
        return new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
      },
      minTillDate(): string {
        if (this.validFrom.date.value) {
          return this.validFrom.date.value;
        }
        const data: string = this.minFromDate();
        console.log("minTillDate:: data ", data);
        return data;
      },
      validFrom: {
        date: {
          show: false,
          value: "" as string | null,
        },
        time: {
          show: false,
          value: "" as string | null,
        },
      },
      validTill: {
        date: {
          show: false,
          value: "" as string | null,
        },
        time: {
          show: false,
          value: "" as string | null,
        },
      },
      lastUpdatedAt: null as Date | null,
      parkingLotName: null as string | null,
      parkingLotId: null as number | null,

      allFieldsValid: false,
      parkingLots: {
        data: [] as Array<ParkingLotsList>,
        isLoading: false,
      },
      badWeatherTemplate: false,
      confirmMessage: false,
      showError: null as string | null,
    };
  },
  components: {
    TimePicker,
    DatePicker,
  },
  computed: {
    isNewMessage(): boolean {
      return this.id == null && this.existingMessage == null;
    },
    currentParkingLot(): ParkingLotsList | null {
      if (this.parkingLotId || this.lotId) {
        let lot_id = this.parkingLotId || this.lotId;
        if (lot_id) {
          let parkingLot = this.parkingLots.data.find(
            (lot) => lot.id === lot_id
          );
          if (parkingLot) {
            return parkingLot;
          }
        }
      }
      return null;
    },
    messageTypeSelected(): boolean {
      return (
        this.sendInAppMessage ||
        this.sendNotificationToAppUsers ||
        this.sendEmailToAppUsers
      );
    },
    checkValidStartDate() {
      if (this.sendInAppMessage) {
        if (this.validFrom.date.value && this.validFrom.time.value) return true;
        else return false;
      }
      return true;
    },
  },

  mounted() {
    if (this.lotId) {
      this.parkingLotId = this.lotId;
    }
    if (this.existingMessage) {
      this.initMessageDetails();
    } else {
      this.validFrom.date.value = new Date().toISOString().substr(0, 10);
      this.validFrom.time.value = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(11, 5);
    }
    this.initParkingLotsList();
    this.getMessageTemplates();
  },

  methods: {
    initMessageDetails() {
      console.log("Loading message details", this.existingMessage);
      if (this.existingMessage) {
        this.id = this.existingMessage.id;
        this.subject = this.existingMessage.subject;
        this.messageText = this.existingMessage.message_text || "";
        this.lastUpdatedAt = this.existingMessage.updated_at;
        this.parkingLotName = this.existingMessage.parking_lot_name;
        if (this.existingMessage.valid_from) {
          const valid_from = this.existingMessage.valid_from.toString();
          const from_datetime = new Date(
            `${valid_from.substr(0, 10)} ${valid_from.substr(11, 8)} UTC`
          );
          this.validFrom.date.value =
            from_datetime.getFullYear() +
            "-" +
            (from_datetime.getMonth() + 1) +
            "-" +
            from_datetime.getDate();
          this.validFrom.time.value = from_datetime.toString().substr(16, 8);
        }
        if (this.existingMessage.valid_till) {
          const valid_till = this.existingMessage.valid_till.toString();
          const till_datetime = new Date(
            `${valid_till.substr(0, 10)} ${valid_till.substr(11, 8)} UTC`
          );
          this.validTill.date.value =
            till_datetime.getFullYear() +
            "-" +
            (till_datetime.getMonth() + 1) +
            "-" +
            till_datetime.getDate();
          this.validTill.time.value = till_datetime.toString().substr(16, 8);
        }
      } else {
        this.validFrom.date.value = new Date().toISOString().substr(0, 10);
        this.validFrom.time.value = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(11, 5);
      }
    },
    disabledFromDate(date: Date) {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 1);
      const inputDate = new Date(date);

      if (inputDate < currentDate) {
        return true;
      }
      return false;
    },
    disabledTillDate(date: Date) {
      const min = this.minTillDate()
        ? new Date(this.minTillDate()).getTime()
        : null;
      const max = null;
      const current = new Date(date).getTime();

      if (min && current < min) return true;
      if (max && current > max) return true;
      return false;
    },
    setValidFromTime(time: string) {
      this.validFrom.time.show = false;
      this.validFrom.time.value = time;
    },
    setValidTillTime(time: string) {
      this.validTill.time.show = false;
      this.validTill.time.value = time;
    },
    async initParkingLotsList() {
      this.parkingLots.isLoading = true;
      let parkingLots = await api.getAllParkingLotsListForUser();
      if (parkingLots && parkingLots.length > 0) {
        this.parkingLots.data = parkingLots;
        // Autofill the parking lot selection if there is only a single lot
        if (parkingLots.length === 1) {
          this.parkingLotId = parkingLots[0]?.id || null;
        }
      }
      this.parkingLots.isLoading = false;
    },

    async getMessageTemplates() {
      this.messageTemplates.isLoading = true;
      if (this.parkingLotId) {
        let message_templates = await api.getMessageTemplates(
          this.parkingLotId,
          this.alertTypeId
        );
        if (message_templates && message_templates.length > 0) {
          this.messageTemplates.data = message_templates;
          if (this.alertTypeId) {
            this.messageTemplates.selected = this.messageTemplates.data[0].id;
            this.setMessageTemplate();
          }
        }
      }
      if (this.forwardMessageText) {
        this.messageText = this.forwardMessageText;
      }
      this.messageTemplates.isLoading = false;
    },

    submitForm() {
      if (this.sendInAppMessage && this.validateMessageDatetime()) return;
      let message = {
        subject: this.subject.trim(),
        message_text: this.messageText.trim(),
        valid_from: this.sendInAppMessage
          ? this.convertToUTCTime(
              this.validFrom.date.value,
              this.validFrom.time.value
            )
          : null,
        valid_till: this.sendInAppMessage
          ? this.convertToUTCTime(
              this.validTill.date.value,
              this.validTill.time.value
            )
          : null,
        is_active: this.sendInAppMessage,
        in_app_message: this.sendInAppMessage,
        send_notification: this.sendNotificationToAppUsers,
        send_email: this.sendEmailToAppUsers,
        alert_id: this.alertId,
      };

      if (this.isNewMessage) {
        if (!this.parkingLotId) {
          this.$dialog.message.error("Please select a parking lot.", {
            position: "top-right",
            timeout: 3000,
          });
          return;
        }
        let newMessage = {
          ...message,
          parking_lot_id: this.parkingLotId,
        };
        this.createMessage(newMessage);
      } else if (this.existingMessage != null) {
        this.updateMessage(this.existingMessage.id, message);
      }
    },

    async createMessage(newMessage: MessageCreate) {
      let message = await api.postNewMessage(newMessage);
      if (message) {
        this.$dialog.message.info("Created new message successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
        this.closeForm();
      } else {
        this.$dialog.message.error(
          "Unable to create new message. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    async updateMessage(messageId: number, message: MessageUpdate) {
      let updatedMessage = await api.putMessage(messageId, message);
      if (updatedMessage) {
        this.$dialog.message.info("Updated message successfully", {
          position: "top-right",
          timeout: 3000,
        });
        this.$emit("refresh-data");
        this.closeForm();
      } else {
        this.$dialog.message.error(
          "Unable to update message. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      }
    },

    setMessageTemplate() {
      if (this.messageTemplates.selected) {
        let message_template = this.messageTemplates.data.find(
          (t) => t.id == this.messageTemplates.selected
        );
        if (message_template) {
          this.subject = message_template.subject;
          this.messageText = message_template.message_text;
        }
      } else {
        this.subject = "";
        this.messageText = "";
      }
    },

    confirmSend() {
      if (this.sendInAppMessage) {
        this.validateMessageDatetime();
      }
      this.confirmMessage = true;
    },

    closeForm() {
      this.resetForm();
      this.$emit("close-form");
    },

    resetForm() {
      this.id = null;
      this.subject = "";
      this.messageText = "";
      this.lastUpdatedAt = null;
      this.parkingLotName = null;
      this.parkingLotId = null;
      this.validFrom.date.show = false;
      this.validFrom.date.value = "";
      this.validFrom.time.show = false;
      this.validFrom.time.value = "";
      this.validTill.date.show = false;
      this.validTill.date.value = "";
      this.validTill.time.show = false;
      this.validTill.time.value = "";
      this.badWeatherTemplate = false;
      this.showError = null;
      (this.$refs.messageFormElm as VForm).resetValidation();
    },

    checkValidFromDate() {
      if (
        this.validFrom.date.show &&
        this.validFrom.date.value &&
        this.validFrom.date.value.length >= 2
      ) {
        this.validFrom.date.show = false;
        if (
          new Date(this.validFrom.date.value).setHours(0, 0, 0, 0) ==
          new Date().setHours(0, 0, 0, 0)
        ) {
          this.validFrom.time.value = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(11, 5);
        } else {
          this.validFrom.time.value = "00:00";
        }
      }
    },

    checkValidTillDate() {
      this.validTill.date.show = false;
      this.validTill.time.value = "23:59";
    },

    convertToUTCTime(date: string | null, time: string | null) {
      if (!date || !time) return null;
      return new Date(new Date(date + " " + time).toUTCString());
    },

    validateMessageDatetime() {
      if (!(this.validFrom.date.value && this.validFrom.time.value)) {
        this.showError =
          "*Please provide Start Date and Time correctly before saving the Message";
        return true;
      }
      if (
        new Date(
          new Date(
            `${this.validFrom.date.value} ${this.validFrom.time.value}`
          ).getTime() +
            5 * 60000
        ) < new Date()
      ) {
        this.showError =
          "*Start date and time must be greater than or equals to Current date and time";
        return true;
      }
      if (
        new Date(`${this.validTill.date.value} ${this.validTill.time.value}`) <
        new Date()
      ) {
        this.showError =
          "*End Date must be greater than or equal to Today's Date";
        return true;
      }
      if (
        new Date(`${this.validFrom.date.value} ${this.validFrom.time.value}`) >
        new Date(`${this.validTill.date.value} ${this.validTill.time.value}`)
      ) {
        this.showError =
          "*Start Date and time must be smaller than End Date and time";
        return true;
      }
      this.showError = null;
      return false;
    },

    // convert 24 hour time to 12 hour time format
    timeConvert(time: string | null) {
      if (!time || time.length <= 0) return "";
      return new Date("1970-01-01T" + time + "Z").toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });
    },
  },

  watch: {
    needsInit(needsInit) {
      this.resetForm();
      if (needsInit) {
        this.initMessageDetails();
        this.initParkingLotsList();
      }
    },
    badWeatherTemplate(value) {
      if (value) {
        this.subject = "Bad Weather";
        this.messageText =
          "Weather Advisory: Parking Spot Status may be affected.";
      } else {
        this.subject = "";
        this.messageText = "";
      }
    },
    messageTypeSelected(value) {
      if (!value) {
        this.showError = "*Please select how this message has to be delivered";
      } else {
        this.showError = null;
      }
    },
    currentParkingLot(value) {
      if (value && value.is_private) {
        this.sendInAppMessage = false;
      } else {
        this.sendInAppMessage = true;
      }
    },
  },
});
