import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/Signin.vue";
import LotDashboard from "@/views/LotDashboard.vue";
import LotTrends from "@/views/LotTrends.vue";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import ParkingLot from "../views/ParkingLotEdit.vue";
import Devices from "@/views/Devices.vue";
import CameraProcessingHistory from "@/views/CameraProcessingHistory.vue";
import OrgUsers from "@/views/OrgUsers.vue";
import Alerts from "@/views/Alerts.vue";
import UntrackedZones from "@/views/UntrackedZones.vue";
import Servers from "@/views/Servers.vue";
import PipelineTestbench from "@/views/PipelineTestbench.vue";
import Messages from "@/views/Messages.vue";
import Schedules from "@/views/Schedules.vue";
import ParkingPermits from "@/views/ParkingPermits.vue";
import EndUserPermitGrants from "@/views/EndUserPermitGrants.vue";
import EnforcementReport from "@/views/EnforcementReport.vue";
import ShowMap from "@/views/ShowMap.vue";
import ShowMapImage from "@/views/ShowMapImage.vue";
import AccuracyReports from "@/views/AccuracyReports.vue";
import AccessNVRInstructions from "@/components/AccessNVRInstructions.vue";
import LprHistory from "@/views/LprHistory.vue";
import AddDeleteUsersPrivateLot from "@/views/AddDeleteUsersPrivateLot.vue";
import LprManagement from "@/views/LprManagement.vue";
import MaintenanceOngoing from "@/views/MaintenanceOngoing.vue";
import EventSubscriptions from "@/views/EventSubscriptions.vue";
import ApiIntegrations from "@/views/ApiIntegrations.vue";
import ActivityLogs from "@/views/ActivityLogs.vue";
import ParkingLotCreate from "@/views/ParkingLotCreate.vue";

Vue.use(VueRouter);

// Note: Routes that have name starting with "Lot" enable showing parking lot
// pages navigation buttons on left side of main webapp toolbar.

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Login",
    meta: {
      title: "Login",
    },
    component: Login,
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      title: "Parking Lots",
    },
    component: Home,
  },
  {
    path: "/lot/map/drawimage/:lotId",
    name: "ShowMap",
    meta: {
      title: "Draw and View Map",
    },
    component: ShowMap,
  },
  {
    path: "/lot/map/:lotId",
    name: "ShowMapImage",
    meta: {
      title: "View Map",
    },
    component: ShowMapImage,
  },
  {
    path: "/lot/:lotId/Devices",
    name: "Devices",
    meta: {
      title: "Devices",
    },
    component: Devices,
    props: (route) => {
      const lotId = Number.parseInt(route.params.lotId);
      return { lotId, parkingLotName: route.params.parkingLotName };
    },
  },
  {
    path: "/about",
    name: "About",
    meta: {
      title: "About",
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/dashboard/:lotId",
    name: "LotDashboard",
    meta: {
      title: "Dashboard",
    },
    component: LotDashboard,
  },
  {
    path: "/dashboard/trends/:lotId",
    name: "LotTrends",
    meta: {
      title: "Trends",
    },
    component: LotTrends,
  },
  {
    path: "/dashboard/alerts/:lotId",
    name: "LotAlerts",
    meta: {
      title: "Alerts",
    },
    component: Alerts,
  },
  {
    path: "/dashboard/messages/:lotId",
    name: "LotMessages",
    meta: {
      title: "Messages",
    },
    component: Messages,
  },
  {
    path: "/dashboard/devices/:lotId",
    name: "LotDevices",
    meta: {
      title: "Devices",
    },
    component: Devices,
  },
  {
    path: "/dashboard/cameras/:lotId/:cameraId/processing_history",
    name: "LotCameraProcessingHistory",
    meta: {
      title: "Camera Processing History",
    },
    component: CameraProcessingHistory,
    props: true,
  },
  {
    path: "/dashboard/edit/map/:lotId",
    name: "LotMapEdit",
    meta: {
      title: "Edit Map",
    },
    component: ParkingLot,
  },
  {
    path: "/dashboard/untrackedzones/:lotId",
    name: "LotUntrackedZones",
    meta: {
      title: "Car Counting Zones",
    },
    component: UntrackedZones,
  },
  {
    path: "/dashboard/schedules/:lotId",
    name: "LotSchedules",
    meta: {
      title: "Schedules",
    },
    component: Schedules,
  },
  {
    path: "/dashboard/nvr/:lotId",
    name: "LotNVR",
    meta: {
      title: "Network Video Recorder",
    },
    component: AccessNVRInstructions,
  },
  {
    path: "/dashboard/parking_permits/:lotId",
    name: "LotPermits",
    meta: {
      title: "Parking Permits",
    },
    component: ParkingPermits,
  },
  {
    path: "/dashboard/parking_permit_grants/:lotId",
    name: "LotEndUserPermitGrants",
    meta: {
      title: "Parking Permit Grants",
    },
    component: EndUserPermitGrants,
  },
  {
    path: "/dashboard/permit_violation_report/:lotId",
    name: "LotEnforcementReportLot",
    meta: {
      title: "Permit Violation Report",
    },
    component: EnforcementReport,
  },
  {
    path: "/dashboard/lpr_management/:lotId",
    name: "LotLprManagement",
    meta: {
      title: "Lpr Management",
    },
    component: LprManagement,
    props: true,
  },
  {
    path: "/dashboard/users_private_lot/:lotId",
    name: "LotAddDeleteUsersPrivateLot",
    meta: {
      title: "Add or Delete Private Lot users",
    },
    component: AddDeleteUsersPrivateLot,
    props: true,
  },

  {
    path: "/orgusers",
    name: "OrgUsers",
    meta: {
      title: "Users",
    },
    component: OrgUsers,
  },
  {
    path: "/alerts",
    name: "Alerts",
    meta: {
      title: "All Alerts",
    },
    component: Alerts,
  },
  {
    path: "/accuracy",
    name: "Accuracy",
    meta: {
      title: "Accuracy Reports",
    },
    component: AccuracyReports,
  },
  {
    path: "/untracked_zones",
    name: "UntrackedZones",
    meta: {
      title: "Car Counting Zones",
    },
    component: UntrackedZones,
  },
  {
    path: "/servers",
    name: "Servers",
    meta: {
      title: "Servers",
    },
    component: Servers,
  },
  {
    path: "/testbench",
    name: "PipelineTestbench",
    meta: {
      title: "Testbench",
    },
    component: PipelineTestbench,
  },
  {
    path: "/messages",
    name: "Messages",
    meta: {
      title: "All Messages",
    },
    component: Messages,
  },
  {
    path: "/schedules",
    name: "Schedules",
    meta: {
      title: "All Schedules",
    },
    component: Schedules,
  },
  {
    path: "/subscriptions",
    name: "EventSubscriptions",
    meta: {
      title: "Event Subscriptions",
    },
    component: EventSubscriptions,
  },
  {
    path: "/integrations_api",
    name: "ApiIntegrations",
    meta: {
      title: "API Integrations",
    },
    component: ApiIntegrations,
  },
  {
    path: "/parking_permits/:lotId",
    name: "ParkingPermits",
    meta: {
      title: "Parking Permits",
    },
    component: ParkingPermits,
  },
  {
    path: "/parking_permit_grants/:lotId",
    name: "EndUserPermitGrants",
    meta: {
      title: "Parking Permit Grants",
    },
    component: EndUserPermitGrants,
  },
  {
    path: "/permit_violation_report",
    name: "EnforcementReport",
    meta: {
      title: "Permit Violation Report",
    },
    component: EnforcementReport,
  },
  {
    path: "/permit_violation_report/:lotId",
    name: "EnforcementReportLot",
    meta: {
      title: "Permit Violation Report",
    },
    component: EnforcementReport,
  },
  {
    path: "/maintenance_ongoing",
    name: "MaintenanceOngoing",
    meta: {
      title: "Maintenance Ongoing, sorry for the inconvenience.",
    },
    component: MaintenanceOngoing,
  },
  {
    path: "/activity_logs",
    name: "ActivityLogs",
    meta: {
      title: "Activity Logs",
    },
    component: ActivityLogs,
    props: true,
  },
  {
    path: "/parking_lot/create",
    name: "Parking Lot Create",
    meta: {
      title: "Create Parking Lot",
    },
    component: ParkingLotCreate,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL_PATH || "/",
  // base: process.env.BASE_URL_PATH,
  // base: window.location.pathname,
  routes,
});

import store from "../store";

// Redirect to Maintenance page if maintenance is ongoing
router.beforeEach((to, from, next) => {
  const storeState: any = store.state;
  console.log(
    "Redirect",
    from.name,
    to.name,
    storeState.user.isMaintenanceOngoing
  );
  if (
    storeState.user.isMaintenanceOngoing &&
    !(from.name === "MaintenanceOngoing" || to.name === "MaintenanceOngoing")
  ) {
    next({ name: "MaintenanceOngoing" });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    // clear parking lot data saved in localstorage
    if (!to.fullPath.includes("dashboard")) {
      localStorage.removeItem("currentLotName");
      window.dispatchEvent(
        new CustomEvent("lot-name-changed", {
          detail: {
            lot_name: null,
          },
        })
      );
    }

    const title = "SpotGenius";
    if (to.meta && to.meta.title) {
      if (to.params && to.params.lotId) {
        let lotNames: any = localStorage.getItem("lotNames");
        if (lotNames) {
          lotNames = JSON.parse(lotNames);
          if (lotNames && lotNames[to.params.lotId]) {
            document.title = `${title} - ${lotNames[to.params.lotId]} ${
              to.meta.title
            }`;
          } else {
            document.title = `${title} - ${to.meta.title}`;
          }
        }
      } else {
        document.title = `${title} - ${to.meta.title}`;
      }
    } else {
      document.title = title;
    }
  });
});

export default router;
