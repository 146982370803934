



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { OrganizationUserType } from "@/api/models";
import OrgUserForm from "@/components/forms/OrgUserForm.vue";
import ParkingLotForm from "@/components/forms/ParkingLotForm.vue";
import OperatorContactForm from "@/components/forms/OperatorContactForm.vue";
import ConfigureNVRForm from "@/components/forms/ConfigureNVRForm.vue";
import OrgUserAuthorizationForm from "@/components/forms/OrgUserAuthorizationForm.vue";
import DateTimePreferences from "./components/forms/DateTimePreferences.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(timezone);
dayjs.extend(utc);

export default Vue.extend({
  name: "App",
  components: {
    OrgUserForm,
    ParkingLotForm,
    ConfigureNVRForm,
    OrgUserAuthorizationForm,
    OperatorContactForm,
    DateTimePreferences,
  },

  data: () => ({
    lotId: null as number | null,
    showDrawer: true,
    showLotDrawer: true,
    showChangePasswordForm: false,
    showEmailPreferencesForm: false,
    showParkingLotForm: false,
    showConfigureNVRForm: false,
    currentParkingLotName: null as string | null,

    maintenanceOngoingInterval: null as number | null,

    showAuthorizationForm: false,

    showDateTimePreferencesForm: false,

    showLotOperatorContactForm: false,
    dashboardPath: process.env.VUE_APP_3_BASE_URL_PATH || "/",
    lotPagePath: process.env.VUE_APP_3_BASE_URL_PATH
      ? `${process.env.VUE_APP_3_BASE_URL_PATH}/home`
      : "/",

    timezoneText: "My Local Time",
    selectedTimezoneText: dayjs().format("(z)"),
  }),

  computed: {
    ...mapGetters("user", [
      "isLoggedIn",
      "currentUserEmail",
      "isSuperAdminViewSwitched",
      "isSuperAdmin",
      "isAdmin",
      "isOperator",
      "isTechnician",
      "isDeveloper",
      "hasAccessLevelDashboardMonitoring",
      "hasAccessLevelApiConfiguration",
      "hasAccessLevelCameraEditing",
      "getCurrentUserData",
      "isUpcomingOngoingMaintenanceWindow",
      "upcomingMaintenanceWindowStartAtLocalTime",
      "isMaintenanceOngoing",
    ]),
    ...mapGetters("data", ["getCurrentParkingLotData"]),

    nameInitials() {
      if (!this.getCurrentUserData) return "";

      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
      let initials = [...this.getCurrentUserData.name.matchAll(rgx)] || [];
      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();

      return initials;
    },
    currentRoute() {
      return this.$route.name;
    },
    isLotRoute() {
      return this.$route.name?.startsWith("Lot");
    },
    userRole() {
      if (this.getCurrentUserData) {
        switch (this.getCurrentUserData.user_type) {
          case OrganizationUserType.superadmin:
            return "Super Admin";
          case OrganizationUserType.admin:
            return "Admin";
          case OrganizationUserType.operator:
            return "Operator";
          case OrganizationUserType.technician:
            return "Technician";
          case OrganizationUserType.developer:
            return "Developer";
          default:
            return null;
        }
      }
      return null;
    },
  },

  created() {
    if (this.$route.params && this.$route.params.lotId) {
      this.lotId = Number(this.$route.params.lotId);
    }
  },

  async mounted() {
    const locale = navigator.language ? navigator.language : "en-US";
    let timezone_short = new Intl.DateTimeFormat(locale, {
      timeZoneName: "long",
    })
      .formatToParts(new Date())
      .find((part) => part.type === "timeZoneName")?.value;
    if (timezone_short) {
      timezone_short = timezone_short
        .split(" ")
        .map((word) => word[0])
        .join("");
      this.selectedTimezoneText = `(${timezone_short})`;
    } else {
      this.selectedTimezoneText = dayjs().format("(z)");
    }

    // Check if the token from a previous session is saved in localstorage, and try to use it
    const token = localStorage.getItem("token");
    if (token) {
      this.loginSuccess(token);
      this.initCurrentOrgUser();
    } else {
      this.initMaintenanceWindow();
      if (
        this.$route.name !== "Login" &&
        this.currentRoute != "ShowMap" &&
        this.currentRoute != "ShowMapImage" &&
        this.currentRoute != "LotTrends" &&
        !localStorage.getItem("url_token")
      ) {
        // Redirect to Login page if there is no saved token and user is not on Login page already
        this.$router.push({ name: "Login" });
      }
    }

    window.addEventListener("storage", () => {
      const token = localStorage.getItem("token");
      // if token cleared refresh page
      if (!token) {
        this.$router.go(0);
      }
    });

    if (this.getCurrentParkingLotData) {
      this.currentParkingLotName = this.getCurrentParkingLotData.name;

      if (
        localStorage.getItem("timezone_option") == "local_lot_timezone" &&
        this.getCurrentParkingLotData.timezone
      ) {
        localStorage.setItem(
          "selected_timezone",
          this.getCurrentParkingLotData.timezone
        );
        let tz_short = "";
        const locale = navigator.language ? navigator.language : "en-US";
        let timezone_short = new Intl.DateTimeFormat(locale, {
          timeZone: this.getCurrentParkingLotData.timezone,
          timeZoneName: "long",
        })
          .formatToParts(new Date())
          .find((part) => part.type === "timeZoneName")?.value;
        if (timezone_short) {
          // abbreviate text
          timezone_short = timezone_short
            .split(" ")
            .map((word) => word[0])
            .join("");
          tz_short = `(${timezone_short})`;
        } else {
          tz_short = dayjs()
            .tz(this.getCurrentParkingLotData.timezone)
            .format("(z)");
        }
        this.selectedTimezoneText = tz_short;
      }
    } else if (localStorage.getItem("currentLotName")) {
      // First check if lot name is right for lot id
      let lotNames = localStorage.getItem("lotNames");
      if (lotNames && this.lotId) {
        let lotNamesObj = JSON.parse(lotNames);
        if (lotNamesObj[this.lotId]) {
          this.currentParkingLotName = lotNamesObj[this.lotId];
        }
      }
      // this.currentParkingLotName = localStorage.getItem("currentLotName");
    }
    window.addEventListener("lot-name-changed", (event: any) => {
      if (event.detail.lot_name) {
        this.currentParkingLotName = event.detail.lot_name;
      } else {
        this.currentParkingLotName = null;
      }
    });

    // check if trends page using path and check if options param in path is true
    if (this.$route.path.includes("dashboard") && this.$route.query.options) {
      this.showParkingLotForm = true;
    }

    const timezone_option = localStorage.getItem("timezone_option");
    if (timezone_option == "my_timezone") {
      this.timezoneText = "My Local Time";
    } else if (timezone_option == "local_lot_timezone") {
      this.timezoneText = "Lot Timezone";
    } else if (timezone_option == "custom_timezone") {
      this.timezoneText = "Custom Timezone";
    }

    const selectedTimezone = localStorage.getItem("selected_timezone");
    if (selectedTimezone) {
      if (
        timezone_option == "my_timezone" &&
        selectedTimezone != Intl.DateTimeFormat().resolvedOptions().timeZone
      ) {
        console.log("System timezone changed, updating selected timezone");
        localStorage.setItem(
          "selected_timezone",
          Intl.DateTimeFormat().resolvedOptions().timeZone
        );
      } else {
        const locale = navigator.language ? navigator.language : "en-US";
        let timezone_short = new Intl.DateTimeFormat(locale, {
          timeZone: selectedTimezone,
          timeZoneName: "long",
        })
          .formatToParts(new Date())
          .find((part) => part.type === "timeZoneName")?.value;
        if (timezone_short) {
          // abbreviate text
          timezone_short = timezone_short
            .split(" ")
            .map((word) => word[0])
            .join("");
          this.selectedTimezoneText = `(${timezone_short})`;
        } else {
          this.selectedTimezoneText = dayjs()
            .tz(selectedTimezone)
            .format("(z)");
        }
      }
    }
  },

  updated() {
    if (!this.isLoggedIn && !localStorage.getItem("url_token")) {
      this.$router.push({ name: "Login" });
    }
    if (this.$route.params && this.$route.params.lotId) {
      this.lotId = Number(this.$route.params.lotId);
    } else {
      this.lotId = null;
    }

    // open lot options form if set
    const open_lot_form = this.$route.query.open_lot_form;
    if (open_lot_form && open_lot_form == "true") {
      this.showParkingLotForm = true;
    }
  },

  methods: {
    ...mapMutations("user", [
      "loginSuccess",
      "logout",
      "dismissUpcomingMaintenance",
    ]),
    ...mapActions("user", [
      "initCurrentOrgUser",
      "initMaintenanceWindow",
      "switchToAdminView",
    ]),
  },

  watch: {
    isLoggedIn(newIsLoggedIn) {
      if (
        newIsLoggedIn === false &&
        this.$route.name !== "Login" &&
        this.currentRoute != "ShowMap" &&
        !localStorage.getItem("url_token")
      ) {
        this.$router.push({
          name: "Login",
        });
      }
    },

    isUpcomingOngoingMaintenanceWindow(newIsUpcoming) {
      if (newIsUpcoming && this.maintenanceOngoingInterval === null) {
        const startsAt = dayjs(newIsUpcoming.starts_at).utc(true).local();
        const now = dayjs();
        const pendingMilliSecs = startsAt.diff(now);
        console.log(
          `Maintenance Upcoming in ${pendingMilliSecs} ms, setting interval to check for maintenance window.`
        );
        this.maintenanceOngoingInterval = setInterval(() => {
          // Check Maintenance endpoint again to see maintenance is ongoing
          this.initMaintenanceWindow();
        }, 10000);
      }
    },

    isMaintenanceOngoing(newIsOngoing) {
      console.log("Is maintenance window changed", newIsOngoing);
      if (newIsOngoing === true && this.$route.name !== "MaintenanceOngoing") {
        this.$router.push({ name: "MaintenanceOngoing" });
      } else if (this.$route.name === "MaintenanceOngoing") {
        window.open(`${process.env.VUE_APP_3_BASE_URL_PATH}/home`, "_self");
      }
    },

    getCurrentParkingLotData(newData) {
      if (newData) {
        this.currentParkingLotName = newData.name;
        if (
          localStorage.getItem("timezone_option") == "local_lot_timezone" &&
          newData.timezone
        ) {
          const locale = navigator.language ? navigator.language : "en-US";
          let timezone_short = new Intl.DateTimeFormat(locale, {
            timeZone: newData.timezone,
            timeZoneName: "long",
          })
            .formatToParts(new Date())
            .find((part) => part.type === "timeZoneName")?.value;
          if (timezone_short) {
            // abbreviate text
            timezone_short = timezone_short
              .split(" ")
              .map((word) => word[0])
              .join("");
            this.selectedTimezoneText = `(${timezone_short})`;
          } else {
            this.selectedTimezoneText = dayjs()
              .tz(newData.timezone)
              .format("(z)");
          }
        }
      }
    },

    showDrawer(newShowDrawer) {
      this.showLotDrawer = newShowDrawer;
    },

    showParkingLotForm(newVal) {
      // remove open_lot_form param on url once form is closed, so that its not opened again on close
      if (!newVal) {
        this.$router.replace({ query: {} });
      }
    },
  },
});
