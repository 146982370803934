










































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import api from "@/api/api";
import { EnforcementSpotReport } from "@/api/models";
import { ParkingStatus } from "@/api/models/ParkingSpot";
import {
  ParkingLotOnlyId,
  ViolationOccured,
  PermitNames,
} from "@/api/models/ParkingLot";
import MapEditor from "../components/MapEditor.vue";

const SPOT_COLORS = {
  free: "#00FF00",
  unavailable: "#FF0000",
  reserved: "#FF0000",
  unknown: "#8B8000",
};

const VIOLATION_COLORS = {
  no: "#00FF00",
  yes: "#FF0000",
  maybe: "#FFA500",
};

interface licensePlate {
  [key: number]: string;
}

interface SortOptions {
  text: string;
  value: string;
}

interface EnforcementSpotReport2 {
  spot_id?: number;
  spot_name: string | null;
  spot_status: string;
  spot_permits?: Array<PermitNames>;
  occupied_at?: Date;
  end_user_email?: string;
  license_plate_number?: Array<string>;
  end_user_permits?: Array<PermitNames>;
  violation_occured: ViolationOccured;
  image?: string;
}

export default Vue.extend({
  name: "EnforcementReport",
  components: {
    MapEditor,
  },
  data: () => ({
    breadcrumbItems: [
      {
        text: "Home",
        disabled: false,
        to: { name: "Home" },
      },
      {
        text: "Permit Violation Report",
        disabled: true,
      },
    ] as Record<string, string | any>,

    lotId: 0,
    isLoading: false,
    enforcementData: [] as Array<EnforcementSpotReport>,
    savedEnforcementData: [] as Array<EnforcementSpotReport>,
    selectedLotId: 0,
    parkingLotsIds: [] as Array<ParkingLotOnlyId>,
    licensePlateNumber: {} as licensePlate,
    currentLicensePlateNumber: "",
    currentSpotId: null as number | null,
    verificationError: {
      spotId: 0,
      message: "",
      success: false,
    },
    filters: {
      spotId: {
        selected: [] as Array<string | null>,
        items: [] as Array<string | null>,
      },
      spotStatus: {
        selected: "",
        items: ["FREE", "UNAVAILABLE", "UNKNOWN", "RESERVED"] as Array<string>,
      },
      violation: {
        selected: "",
        items: ["YES", "NO", "MAYBE"] as Array<string>,
      },
      spotPermits: {
        selected: [] as Array<string>,
        items: [] as Array<PermitNames>,
      },
      endUserPermits: {
        selected: [] as Array<string>,
        items: [] as Array<PermitNames>,
      },
      sortBy: {
        selected: "Violation",
        items: [
          { text: "Violation (YES > MAYBE > NO)", value: "Violation" },
          { text: "Spot ID", value: "Spot ID" },
          {
            text: "Spot Status (UNKNOWN > UNAVAILABLE > FREE > RESERVED)",
            value: "Spot Status",
          },
        ] as Array<SortOptions>,
      },
    },
    updated_at: null as Date | null,
    showMap: false,
    autoRefreshIntervalId: null as number | null,
  }),

  async mounted() {
    this.lotId = Number(this.$route.params.lotId);
    if (this.lotId) {
      this.selectedLotId = this.lotId;
    }
    await this.getData(0);
    this.autoRefreshIntervalId = setInterval(async () => {
      if (this.lotId) {
        await this.getData(this.lotId, false);
      }
    }, 60 * 1000);

    this.breadcrumbItems[0].text = this.selectedParkingLotName;
    this.breadcrumbItems[0].to = { name: "LotDashboard" };
  },

  computed: {
    ...mapGetters("user", ["isOperator"]),
    selectedParkingLotName(): string {
      if (this.lotId) {
        const parking_lot = this.parkingLotsIds.find(
          (lot) => lot.id == this.lotId
        );
        if (parking_lot) return `${parking_lot.name}`;
      }
      return "";
    },
  },

  methods: {
    async getData(id: number, showLoader = true) {
      if (showLoader) {
        this.resetFilters();
      }
      this.clearLicenseplate();
      if (id) {
        this.lotId = id;
      }
      try {
        if (showLoader) {
          this.isLoading = true;
        }
        const enforcementData = await api.getEnforcementReport(this.lotId);
        if (enforcementData) {
          this.updated_at = new Date();
          const data = this.checkIfViolationAlreadyVerified(
            enforcementData.data
          );
          this.enforcementData = data;
          this.savedEnforcementData = data;
          this.parkingLotsIds = enforcementData.all_parking_lot_ids;
          if (this.lotId) this.selectedLotId = this.lotId;
          for (let spot of enforcementData.data) {
            this.licensePlateNumber[spot.spot_id] = "";
          }

          this.filters.spotId.items = enforcementData.data
            .filter((d) => d.spot_name != null || d.spot_name != undefined)
            .map((d) => d.spot_name);
          this.filters.spotPermits.items = [
            ...enforcementData.all_permits_names.filter(
              (permit) => permit.name != "Privilege Permit"
            ),
          ];
          this.filters.endUserPermits.items = [
            ...enforcementData.all_permits_names,
          ];
          this.filterSpots();
        }
      } catch (e) {
        console.log(e);
        this.$dialog.message.error(
          "Error, failed to load the Enforcement Report. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
      } finally {
        this.isLoading = false;
      }
    },
    async verifyLicensePlate(spotId: number) {
      if (this.currentLicensePlateNumber && this.lotId && spotId) {
        try {
          this.isLoading = true;
          const res = await api.verifyLicensePlate(
            spotId,
            this.currentLicensePlateNumber
          );
          if (res) {
            this.verificationError.spotId = res.spot_id;
            this.verificationError.message = res.message;
            this.verificationError.success = res.success;

            if (res.success) {
              const index = this.enforcementData.findIndex((spot) => {
                return spot.spot_id === res.spot_id;
              });
              if (index !== -1 && res.end_user_details) {
                this.enforcementData[index].end_user_email =
                  res.end_user_details.end_user_email;
                if (res.end_user_details.license_plate_number) {
                  this.enforcementData[index].license_plate_number =
                    res.end_user_details.license_plate_number;
                }
                if (res.end_user_details.end_user_permits) {
                  this.enforcementData[index].end_user_permits =
                    res.end_user_details.end_user_permits;
                }
                // save verified result in localStorage, so subsequent refresh shows the same
                let savedViolation = [];
                if (localStorage.getItem("verifiedSpotViolations")) {
                  const localSavedViolationData = localStorage.getItem(
                    "verifiedSpotViolations"
                  );
                  if (localSavedViolationData) {
                    savedViolation = JSON.parse(localSavedViolationData);
                  }
                }
                savedViolation.push(this.enforcementData[index]);
                localStorage.setItem(
                  "verifiedSpotViolations",
                  JSON.stringify(savedViolation)
                );
              }
              const idx = this.savedEnforcementData.findIndex((spot) => {
                return spot.spot_id === res.spot_id;
              });
              if (idx !== -1 && res.end_user_details) {
                this.savedEnforcementData[index].end_user_email =
                  res.end_user_details.end_user_email;
                if (res.end_user_details.license_plate_number) {
                  this.savedEnforcementData[index].license_plate_number =
                    res.end_user_details.license_plate_number;
                }
                if (res.end_user_details.end_user_permits) {
                  this.savedEnforcementData[index].end_user_permits =
                    res.end_user_details.end_user_permits;
                }
              }
            }
          }
        } catch (e) {
          console.log(e);
          this.$dialog.message.error(
            "Error, failed to verify license plate. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        } finally {
          this.isLoading = false;
        }
      }
    },
    checkIfViolationAlreadyVerified(arrData: Array<EnforcementSpotReport>) {
      if (localStorage.getItem("verifiedSpotViolations")) {
        const localSavedViolationData = localStorage.getItem(
          "verifiedSpotViolations"
        );
        if (localSavedViolationData) {
          const savedViolation = JSON.parse(localSavedViolationData);
          for (let spot of savedViolation) {
            const new_spot_index = arrData.findIndex(
              (a) =>
                a.spot_id == spot.spot_id && a.occupied_at == spot.occupied_at
            );
            if (new_spot_index !== -1) {
              arrData[new_spot_index] = spot;
            }
          }
        }
      }
      return [...arrData];
    },
    async setViolation(spotId: number, violation: ViolationOccured) {
      // Generate Parking Spot Permit Violation alert if violation occured
      let success = false as boolean | null;
      if (violation == ViolationOccured.yes) {
        success = await this.generateParkingSpotPermitViolationAlert(spotId);

        if (success == true) {
          violation = ViolationOccured.yes;
        } else {
          violation = ViolationOccured.no;
        }
      }

      const index = this.enforcementData.findIndex((spot) => {
        return spot.spot_id === spotId;
      });
      if (index !== -1) {
        this.enforcementData[index].violation_occured = violation;
        // save verified result in localStorage, so subsequent refresh shows the same
        let savedViolation = [];
        if (localStorage.getItem("verifiedSpotViolations")) {
          const localSavedViolationData = localStorage.getItem(
            "verifiedSpotViolations"
          );
          if (localSavedViolationData) {
            savedViolation = JSON.parse(localSavedViolationData);
          }
        }
        savedViolation.push(this.enforcementData[index]);
        localStorage.setItem(
          "verifiedSpotViolations",
          JSON.stringify(savedViolation)
        );
      }
      const index2 = this.savedEnforcementData.findIndex((spot) => {
        return spot.spot_id === spotId;
      });
      if (index2 !== -1) {
        this.savedEnforcementData[index].violation_occured = violation;
      }
    },
    async generateParkingSpotPermitViolationAlert(spotId: number) {
      try {
        const success = await api.markSpotPermitViolation(this.lotId, spotId);
        if (success) {
          this.$dialog.message.success(
            "Parking Spot Permit Violation Alert generated successfully.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        } else {
          this.$dialog.message.error(
            "Error, failed to generate Parking Spot Permit Violation Alert. Please try again later.",
            {
              position: "top-right",
              timeout: 3000,
            }
          );
        }
        return success;
      } catch (e) {
        this.$dialog.message.error(
          "Error, failed to generate Parking Spot Permit Violation Alert. Please try again later.",
          {
            position: "top-right",
            timeout: 3000,
          }
        );
        return false;
      }
    },
    clearLicenseplate(spot_id = -1) {
      this.currentLicensePlateNumber = "";
      this.currentSpotId = null;
      this.verificationError.spotId = 0;
      this.verificationError.message = "";
      this.verificationError.success = false;
      if (spot_id) {
        const index = this.enforcementData.findIndex((spot) => {
          return spot.spot_id === spot_id;
        });
        if (index !== -1) {
          this.enforcementData[index].end_user_email = "";
          this.enforcementData[index].license_plate_number = [];
          this.enforcementData[index].end_user_permits = [];
          // save verified result in localStorage, so subsequent refresh shows the same
          let savedViolation: Array<EnforcementSpotReport> = [];
          if (localStorage.getItem("verifiedSpotViolations")) {
            const localSavedViolationData = localStorage.getItem(
              "verifiedSpotViolations"
            );
            if (localSavedViolationData) {
              savedViolation = JSON.parse(localSavedViolationData);
              savedViolation = savedViolation.filter(
                (s) => s.spot_id != spot_id
              );
              localStorage.setItem(
                "verifiedSpotViolations",
                JSON.stringify(savedViolation)
              );
            }
          }
        }
        const index2 = this.savedEnforcementData.findIndex((spot) => {
          return spot.spot_id === spot_id;
        });
        if (index2 !== -1) {
          this.savedEnforcementData[index].end_user_email = "";
          this.savedEnforcementData[index].license_plate_number = [];
          this.savedEnforcementData[index].end_user_permits = [];
        }
      }
    },
    getSpotColor(status: ParkingStatus) {
      return SPOT_COLORS[status];
    },
    getViolationColor(violation: ViolationOccured) {
      return VIOLATION_COLORS[violation];
    },
    filterSpots() {
      this.enforcementData = [...this.savedEnforcementData];
      if (this.filters.spotId.selected.length > 0) {
        this.enforcementData = this.enforcementData.filter((d) =>
          this.filters.spotId.selected.includes(d.spot_name)
        );
      }
      if (this.filters.spotStatus.selected) {
        this.enforcementData = this.enforcementData.filter(
          (d) =>
            d.spot_status.toLowerCase() ==
            this.filters.spotStatus.selected.toLowerCase()
        );
      }
      if (this.filters.violation.selected) {
        this.enforcementData = this.enforcementData.filter(
          (d) =>
            d.violation_occured.toLowerCase() ==
            this.filters.violation.selected.toLowerCase()
        );
      }
      if (this.filters.spotPermits.selected.length > 0) {
        this.enforcementData = this.enforcementData.filter(
          (d) =>
            d.spot_permits &&
            d.spot_permits.some((permit) =>
              this.filters.spotPermits.selected.includes(permit.name)
            )
        );
      }
      if (this.filters.endUserPermits.selected.length > 0) {
        this.enforcementData = this.enforcementData.filter(
          (d) =>
            d.end_user_permits &&
            d.end_user_permits.some((permit) =>
              this.filters.endUserPermits.selected.includes(permit.name)
            )
        );
      }
      if (!this.filters.sortBy.selected) {
        this.filters.sortBy.selected = "Violation";
      }
      if (this.filters.sortBy.selected) {
        if (this.filters.sortBy.selected === "Violation") {
          let tempData = [] as Array<EnforcementSpotReport>;
          tempData = [
            ...tempData,
            ...this.enforcementData.filter(
              (spot) => spot.violation_occured == ViolationOccured.yes
            ),
          ];
          tempData = [
            ...tempData,
            ...this.enforcementData.filter(
              (spot) => spot.violation_occured == ViolationOccured.maybe
            ),
          ];
          tempData = [
            ...tempData,
            ...this.enforcementData.filter(
              (spot) => spot.violation_occured == ViolationOccured.no
            ),
          ];
          this.enforcementData = [...tempData];
        } else if (this.filters.sortBy.selected === "Spot ID") {
          this.enforcementData = [
            ...this.enforcementData.sort((a, b) =>
              a.spot_id > b.spot_id ? 1 : b.spot_id > a.spot_id ? -1 : 0
            ),
          ];
        } else if (this.filters.sortBy.selected === "Spot Status") {
          let tempData = [] as Array<EnforcementSpotReport>;
          tempData = [
            ...tempData,
            ...this.enforcementData.filter(
              (spot) => spot.spot_status == "unknown"
            ),
          ];
          tempData = [
            ...tempData,
            ...this.enforcementData.filter(
              (spot) => spot.spot_status == ParkingStatus.unavailable
            ),
          ];
          tempData = [
            ...tempData,
            ...this.enforcementData.filter(
              (spot) => spot.spot_status == ParkingStatus.free
            ),
          ];
          tempData = [
            ...tempData,
            ...this.enforcementData.filter(
              (spot) => spot.spot_status == ParkingStatus.reserved
            ),
          ];
          this.enforcementData = [...tempData];
        }
      }
    },
    restrictedPermitOnSpot(spotPermits: Array<PermitNames> | undefined) {
      if (!spotPermits) return false;
      for (let permit of spotPermits) {
        if (permit.is_restricted) {
          return true;
        }
      }
      return false;
    },
    JSONToCSVConvertor() {
      let tempData = [] as Array<EnforcementSpotReport2>;
      tempData = [
        ...tempData,
        ...this.savedEnforcementData.filter(
          (spot) => spot.violation_occured == ViolationOccured.yes
        ),
      ];
      tempData = [
        ...tempData,
        ...this.savedEnforcementData.filter(
          (spot) => spot.violation_occured == ViolationOccured.maybe
        ),
      ];
      tempData = [
        ...tempData,
        ...this.savedEnforcementData.filter(
          (spot) => spot.violation_occured == ViolationOccured.no
        ),
      ];
      tempData = tempData.map((spot) => {
        delete spot.spot_id;
        return spot;
      });

      const arrData = [...tempData];
      let CSV = "\n";

      let rowData = "";
      for (var index in arrData[0]) {
        rowData += index + ",";
      }
      rowData = rowData.slice(0, -1);
      CSV += rowData + "\r\n";

      for (let i = 0; i < arrData.length; i++) {
        let row = "";
        for (let index in arrData[i]) {
          if (index == "spot_permits" || index == "end_user_permits") {
            let permitsStr = "";
            const arrPermits: Array<PermitNames> = arrData[i][
              index as keyof EnforcementSpotReport
            ] as Array<PermitNames>;
            if (
              arrPermits &&
              Array.isArray(arrPermits) &&
              arrPermits.length > 0
            ) {
              for (let [idx, permit] of arrPermits.entries()) {
                if (permit.name) {
                  if (idx == 0) permitsStr += `${permit.name}`;
                  else permitsStr += `, ${permit.name}`;
                }
              }
            }
            row += `"${permitsStr}",`;
          } else {
            row += `"${
              arrData[i][index as keyof EnforcementSpotReport] || ""
            }",`;
          }
        }
        row.slice(0, row.length - 1);
        CSV += row + "\r\n";
      }

      if (CSV == "") {
        alert("Invalid data");
        return;
      }

      let fileName = this.updated_at
        ? `${this.selectedParkingLotName}-${this.updated_at.toLocaleString(
            "en-US",
            {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              hour: "numeric",
              hour12: true,
              minute: "numeric",
              second: "numeric",
            }
          )}`
        : `${this.selectedParkingLotName}-${new Date().toLocaleString("en-US", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            hour: "numeric",
            hour12: true,
            minute: "numeric",
            second: "numeric",
          })}`;

      let uri = "data:text/csv;charset=utf-8," + escape(CSV);
      let link = document.createElement("a");
      link.href = uri;
      link.setAttribute("style", "visibility:hidden");
      link.download = fileName + ".csv";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    downloadData() {
      if (this.savedEnforcementData.length > 0) {
        this.JSONToCSVConvertor();
      }
    },
    resetFilters() {
      this.filters.spotId.selected = [];
      this.filters.spotStatus.selected = "";
      this.filters.violation.selected = "";
      this.filters.spotPermits.selected = [];
      this.filters.endUserPermits.selected = [];
      this.clearLicenseplate();
    },

    lastUpdatedAt() {
      if (this.updated_at) {
        let dayObj = dayjs(this.updated_at);
        const selectedTimezone = localStorage.getItem("selected_timezone");
        if (selectedTimezone) {
          dayObj = dayjs(this.updated_at).tz(selectedTimezone);
        }
        const selectedTimeFormat = localStorage.getItem("time_format_option");
        let formattedDate = dayObj.format("h:mm:ss A");
        if (selectedTimeFormat === "24_hr") {
          formattedDate = dayObj.format("HH:mm:ss");
        }

        let tz_short = "";
        if (selectedTimezone) {
          const locale = navigator.language ? navigator.language : "en-US";
          let timezone_short = new Intl.DateTimeFormat(locale, {
            timeZone: selectedTimezone,
            timeZoneName: "long",
          })
            .formatToParts(new Date())
            .find((part) => part.type === "timeZoneName")?.value;
          if (timezone_short) {
            // abbreviate text
            timezone_short = timezone_short
              .split(" ")
              .map((word) => word[0])
              .join("");
            tz_short = `${timezone_short}`;
          } else {
            tz_short = dayjs().tz(selectedTimezone).format("z");
          }
        }

        return formattedDate + ` ${tz_short}`;
      }
      return "";
    },
  },

  watch: {
    currentLicensePlateNumber(oldVal, newVal) {
      if (oldVal == "" || oldVal == null) {
        this.currentSpotId = null;
      }
    },
  },

  destroyed() {
    if (this.autoRefreshIntervalId !== null) {
      clearInterval(this.autoRefreshIntervalId);
      this.autoRefreshIntervalId = null;
    }
  },
});
