import axios from "axios";
import { sgConnectApi } from "../main";
import type {
  ApiToken,
  parkingTimingData,
  sgConnectParkingData,
} from "@/api/models/SgconnectIntegration";

export default {
  async generateToken(org_id: number | string): Promise<ApiToken | null> {
    try {
      const formData = new FormData();
      formData.append("org_id", org_id as string);
      const response = await axios.post(`/v1/oauth/token-sgconnect`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const sgToken = response.data.data.find((v: ApiToken) => v.token);
      return sgToken;
    } catch (error: any) {
      return error;
    }
  },

  async parkingTiming(
    parking_lot_id: number,
    req_data: any,
    token: string
  ): Promise<parkingTimingData | null> {
    try {
      const orgID = req_data.orgID;
      delete req_data.orgID;
      const response = await sgConnectApi.post(
        `/v1/parking_lot/${parking_lot_id}/timing/parking_timing`,
        req_data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            organization: orgID,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return error;
    }
  },

  async getParkingTiming(
    id: number | null,
    token: string | null,
    orgID: number
  ): Promise<sgConnectParkingData | null> {
    try {
      const queryParams = new URLSearchParams({
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      const response = await sgConnectApi.get(
        `/v1/parking/timing/${id}/not-paid?${queryParams.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            organization: orgID,
          },
        }
      );
      return response.data.data;
    } catch (error: any) {
      return error;
    }
  },

  async detachNPH(
    id: number | null,
    token: string | null,
    orgID: number
  ): Promise<sgConnectParkingData | null> {
    try {
      const response = await sgConnectApi.delete(
        `/v1/parking/timing/${id}/not-paid`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            organization: orgID,
          },
        }
      );
      return response.data.data;
    } catch (error: any) {
      return error;
    }
  },
};
